@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800&family=Orbitron:wght@400;500;600;700;800;900&family=Roboto+Mono:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@100;200;300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Orbitron:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600&family=Orbitron:wght@400;500;600&family=Roboto+Mono:wght@100;200;300;400;500;600;700&display=swap");

:root {
    --font-base: "Orbitron", sans-serif;
    --font-alt: "Inter", sans-serif;
    --font-mono: "Roboto Mono", monospace;

    --color-black: #101010;
    --color-mint: #a1e4c6;
    --color-white: #fafafa;
    --color-lightgray: #3f3f3f;
    --color-gray: #2d2d2d;
    --color-red: #bf292a;
    --color-mint: #cad9c7;

    --color-gray-transparent: rgba(42, 42, 42, 0.65);
    --color-white-transparent: rgba(255, 255, 255, 0.2);
    --color-black-transparent: rgba(16, 16, 16, 0.45);
}

.cockpit__container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    font-size:.8rem;
    margin-top:20px;
}

.cockpit__cards {
    width: 500px;
    margin-left: auto;
}

.cockpit__header-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.cockpit__card {
    text-transform: uppercase;
    padding: 1rem;
    border: 1px solid rgb(89, 89, 89);
    border-radius: 0.2rem;
    margin: 1rem;
    flex: 1;
    background-color: var(--color-black-transparent);
    flex-wrap: wrap;
}

.cockpit__card-header {
    font-family: var(--font-alt);
    font-weight: 500;
    margin-top:10px;
}

.cockpit__card-info {
    font-family: var(--font-mono);
    font-weight: 200;
}

.cockpit__tables {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

#explorers__info {
    display: flex;
}

.cockpit__table {
    margin: 1rem;
    display: flex;
    flex: 1;
}

#cockpit__explorers-card {
    display: flex;
    justify-content: space-between;
}

#cockpit_explorers-card_left {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.stakedProgress {
    display: flex;
    flex-direction: column;
    font-family: var(--font-mono);
    font-weight: 200;
    flex-wrap: wrap;
    text-align: center;
}

.stakedProgress-header {
    font-family: var(--font-alt);
    font-weight: 400;
    margin-top: 1.5rem;
}

.stakedProgress-info {
    font-family: var(--font-mono);
    font-weight: 200;
}

#stakedLinearProgress {
    text-align: center;
    margin-top: 1rem;
    display: none;
}

#stakedLinearProgress .stakedProgress-header {
    margin-top: 1px;
}

#stakedLinearProgress .stakedProgress-info {
    margin-bottom: 1rem;
}

.MuiDataGrid-root {
    background-color: var(--color-black-transparent);
}

#stakedLinearProgress {
    display: block;
}

.cockpit__table {
    margin: 0.2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: auto;
    width: 100%;
}

.cockpit__card {
    flex: auto;
    margin: 0.2rem;
}

.modal {
    inset: auto;
    margin-right: auto;
    transform: none;
    z-index: 5;
}

@media screen and (max-width: 1150px) {
    
    .cockpit__cards {
        margin-top:35px;
        width: 100vw;
    }
}