:root {
    --fontSize-header: 1.2rem;
    --fontSize-info: 1.1rem;
}

.login-modal {
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 0.2rem;
    border: 1px solid var(--color-gray);
    text-transform: uppercase;
    background-color: var(--color-black);
    width: 400px;
    outline: none;
}

.login-container {
    display: flex;
    flex-direction: column;
    padding-top: 35px;
}

#login-close {
    color: var(--color-gray);
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 35px;
    cursor: pointer;
}

#login-close:hover {
    color: var(--color-light-gray);
}

.login-button-title {
    font-family: var(--font-base);
    color: var(--color-white);
    text-align: center;
    padding: 1rem;
    font-size: 1.2rem;
    font-weight: 500;
    letter-spacing: 0.05rem;
}

.login-button-text {
    font-size: 1.1rem !important;
    margin-left: 4rem !important;
}

.login-button-container {
    display: flex !important;
    flex-direction: row !important;
    flex-direction: row !important;
    align-items: center !important;
    cursor: pointer !important;
    font-family: var(--font-mono) !important;
    text-transform: uppercase !important;
    font-weight: 200 !important;
}

.login-button-container:hover {
    background-color: var(--color-gray) !important;
}

.btn-connect:hover {
    background-color: var(--color-gray) !important;
}

.login-button-container a {
    text-decoration: none !important;
    width: 100% !important;
}

.btn-connect {
    margin: 0 !important;
    padding: 0 !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    background-color: var(--color-black) !important;
    width: 100% !important;
    font-family: var(--font-mono) !important;
    text-transform: uppercase !important;
    font-weight: 200 !important;
    border: none !important;
}

.unlock-icon {
    position: absolute;
    left: 1rem;
    padding: 0;
}

#unlock-icon_ledger {
    width: 20px;
    height: 20px;
    fill: white;
}

.maiar-modal {
    color: var(--color-black);
}

.maiar-modal > :first-child {
    color: var(--color-white);
    border: 1px solid var(--color-gray);
    text-transform: uppercase;
    padding-top: 20px !important;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 0.2rem;
    background-color: var(--color-black);
    width: 400px;
    outline: none;
}

.maiar-modal div {
    color: var(--color-white);
    background-color: var(--color-black);
    font-family: var(--font-mono);
    text-transform: uppercase;
    font-size: var(--fontSize-info);
    font-weight: 200;
    text-align: center;
    padding: 0;
}

.dapp-modal-dialog-close-button {
    background-color: var(--color-black);
    color: var(--color-gray);
    position: absolute;
    cursor: pointer;
}

.dapp-modal-dialog-close-button:hover {
    background-color: var(--color-black);
    color: var(--color-white);
}

.maiar-modal p {
    font-size: var(--fontSize-info);
    font-weight: 200;
}

.maiar-modal h4 {
    display: none;
}

#accessWalletBtn {
    border:1px solid var(--color-gray);
    background-color: var(--color-gray);
    margin: 0;
    text-decoration: none;
}

@media screen and (max-width: 1150px) {
    .login-modal {
        width: 90vw;
    }

    .maiar-modal > :first-child {
        width: 90vw;
    }
}