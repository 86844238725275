// landing
.landing__body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
}

.landing__title {
    color: var(--color-white);
    font-family: var(--font-base);
    text-transform: uppercase;
    font-weight: 500;
    font-size: 4rem;
    margin: 3rem;
}
.landing__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
}

.landing__button {
    color: var(--color-black);
    font-family: var(--font-base);
    font-weight: 500;
    font-size: 1rem;
    cursor: pointer;
    text-transform: uppercase;
    background-color: var(--color-white);
    padding: 0.7rem 2rem;
    border-radius: 0.15rem;
    border: 0.1px solid var(--color-white);
}

.landing__button:hover {
    background: rgba(20, 20, 20, 0.25);
    color: var(--color-white);
    border: 0.1px solid var(--color-white);
}

.landing__logo {
    width: 5rem;
    margin-top: 6rem;
}

.ReactModal__Overlay {
    background: rgba(16, 16, 16, 0.9) !important;
    backdrop-filter: blur(5px);
}

@media screen and (max-width: 1150px) {
    .landing__body-logo {
        width: 80px;
        margin-top: 5rem;
    }

    .landing__title {
        font-size: 2rem;
    }

    .landing__subtitle {
        font-size: 1rem;
        letter-spacing: 1rem;
    }
}

::-webkit-scrollbar {
    width: 3px; /* for vertical scrollbars */
    height: 0px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.1);
}

::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5);
}
