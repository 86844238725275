.navbar {
    display: flex;
    justify-content: space-between;
    
    background-color: var(--color-black);
    font-family: var(--font-mono);
    font-weight: 300;
    border-top: .5px solid var(--color-gray);
    border-bottom: .5px solid var(--color-gray);
    padding: 1.35rem 3rem;

    display:flex;
    align-items: center;
}

.navbar-header_logo {
    align-self: flex-start;
    justify-self: start;
}

.navbar-address {
    font-size:.8rem;
}

.navbarItem {
    text-transform: uppercase;
    margin: 0 .8rem;
    cursor: pointer;
}

.navbar-smallscreen {
    display:none;
}

.navbar-header {
    display:none;
}

.navbar-header_logo img{
    width:50px;
}

.app__navbar-logo img {
    width: 150px;
}

@media screen and (max-width: 1150px) {
    .navbar {
        display: none;
    }

    .cockpit__fleetname {
        display:none;
    }

    .navbar-smallscreen {
        display:block;
        position: fixed;
        width: 100vw;
        z-index:0;
        border-bottom: 1px solid var(--color-gray);
        top:0;
    }

    .navbar-header {
        width: 100vw;
        display:flex;
        justify-content: space-between;
        align-items: center;
        color:white;
        padding: 8px;
        background-color: var(--color-black);
    }

    .navbar-header_title {
        font-family: var(--font-base);
        font-weight: 600;
        text-transform: uppercase;
        align-content: center;
    }

    #navbar-smallscreen_overlay{
        height:100vh;
        outline:none;
    }

    .navbar-smallscreen_links {
        text-align: center;
        display:flex;
        flex-direction: column;
        align-items: center;
        text-transform: uppercase;
        padding: 3rem 0;
        font-size: 2rem;
        text-align: center;
        width: 100vw;
    }

    .navbar-smallscreen_links div{
        padding: .5rem 0;
        font-size: 2rem;
        text-align: center;
    }

    .navbar-smallscreen_links a{
        text-decoration: none;
        color:white;
    }

    .app__navbar-smallscreen_overlay-menu {
        display: flex;
        align-items: center;
        padding: 1rem 2rem;
        justify-content: space-between;
    }

    .app__navbar-smallscreen_socials a {
        color: var(--color-black);
        cursor: pointer;
        padding: 1rem 0rem 0rem 1.75rem;
        font-size: 1.75rem;
        flex-direction: column;
        align-items: flex-end;
    }
}
